import './Loading.css';
import React from 'react';

const Loading = (props) => {
    return (
        <div className="ui active loader"></div>
    );
};

Loading.defaultProps = {
  message: 'Loading...'
};

export default Loading;
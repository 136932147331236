import './Clock.css';
import React from 'react';
import Loading from './Loading';

class Clock extends React.Component {

    state = {
        time: new Date().toLocaleTimeString()
    }

    componentDidMount() {
        setInterval(() => {
            this.setState({ time: new Date().toLocaleTimeString() });    
        }, 1000)
    }

    //helper function
    renderContent() {
        if(this.state.time) {
            return (
                <h1>{this.state.time}</h1>
            );
        }
        return (
            <Loading />
        );
    }

    render() {
        return (
            <div className="clock">
                    {this.renderContent()}
            </div>
        );
    }
    
}


export default Clock;

